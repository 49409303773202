<template>

  <div>
      <v-toolbar flat dense :color="$vuetify.theme.dark ? '#1E1E1E' : 'white'">
      <v-toolbar-title>{{ $t('widgets.agenda.name') }}</v-toolbar-title>
      <add-event @add="add"></add-event>
    </v-toolbar>
    <v-card-text v-if="!moving">
      <v-layout  :class="$vuetify.theme.dark ? 'grey darken-5' : 'white'">
        <v-btn icon @click="prev()">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn icon @click="next()">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
        <v-btn text @click="cal = moment()">{{ cal.locale($i18n.locale).format("MMMM YYYY") }}</v-btn>
        <v-spacer></v-spacer>

        <v-switch-text
            :value="type"
            @input="saveAgendaView"
            :labels="labelDisplayAs"
            color="primary"
            x-small
        >
        </v-switch-text>
      </v-layout>
      <v-calendar
          v-model="modelDate"
          ref="calendar"
          :type="type"
          :weekdays="[1,2,3,4,5,6,0]"
          :locale="locale"
          :events="events"
          color="primary"
          @click:event="showEvent"
          @click:date="viewDay"
      >
      </v-calendar>

      <view-event v-model="selectedOpen"
                  :selectedEvent="selectedEvent"
                  :selectedElement="selectedElement"
                  @update="update"
                  @close="close"
                  @remove="remove"
      >
      </view-event>

    </v-card-text>
  </div>


</template>

<script>
import moment from "moment"
import Vuex from "vuex"

export default {
  name: "WidgetAgenda",
  props: {
    moving: Boolean,
    dark: {
      type: Boolean,
      default() {
        return this.$vuetify.theme.dark
      }
    }
  },
  components: {
    ViewEvent: () => import("@/Components/Views/Home/Widgets/WidgetAgenda/ViewEvent"),
    FastButton: () => import("@/Components/Views/Home/Widgets/WidgetAgenda/FastButton"),
    AddEvent: () => import("@/Components/Views/Home/Widgets/WidgetAgenda/AddEvent")
  },
  computed: {
    ...Vuex.mapState(['widgetBoard', "preferences"]),
    type: {
      get() {
        let agendaDatas = this.$store.getters.getWidgetDatas("agenda")
        return agendaDatas.preferences != undefined ? agendaDatas.preferences.view : "month"
      },
      set(val) {
        this.saveAgendaView(val)
      }
    },
    labelDisplayAs() {
      return {
        month: this.$t("widgets.agenda.periods.month"),
        week: this.$t("widgets.agenda.periods.week"),
        day: this.$t("widgets.agenda.periods.day")
      }
    },
    locale: function () {
      if (this.$i18n.locale == "fr-CO") {
        return "co"
      } 
      else {
        return this.$i18n.locale
      }
    },
    events() {
      return this.$store.getters.getWidgetDatas("agenda").events
          .map(a => {
            return {
              ...a,
              start: new Date(a.start),
              end: a.end ? new Date(a.end) : new Date(a.start)
            }
          }) // Converti la date stockée en BDD en Date()
    },
    modelDate: {
      get() {
        return this.cal.format("Y-MM-D")
      },
      set(newDate) {
        this.cal = moment(newDate)
      }
    }
  },
  data() {
    return {
      moment,
      cal: moment(),
      fab: false,
      daysOfWeek: [1, 2, 3, 4, 5, 6, 0],
      focus: '',
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
    }
  },
  methods: {
    next() {
      this.cal = moment(this.cal).add(1, this.type)
    },
    prev() {
      this.cal = moment(this.cal).add(-1, this.type)
    },
    showEvent({nativeEvent, event}) {
      const open = () => {
        if (this.events) {
          let i = this.events.findIndex(a => a.id == event.id)
          this.selectedEvent = this.events[i]
          this.selectedElement = nativeEvent.target
          setTimeout(() => {
            this.selectedOpen = true
          }, 10)
        }
      }
      if (this.selectedOpen) {
        this.selectedOpen = false
        setTimeout(open, 10)
      } else {
        open()
      }
      nativeEvent.stopPropagation()
    },
    update: function (data) {
      this.$emit("update", {
        widgetName: "agenda",
        dimension: "events",
        data: data
      })
      this.selectedOpen = false
    },
    add: function (data) {
      this.$emit("add", {
        widgetName: "agenda",
        dimension: "events",
        data: data
      })
    },
    remove: function (data) {
      this.selectedOpen = false
      this.$emit("remove", {
        widgetName: "agenda",
        dimension: "events",
        data: data
      })
    },
    viewDay({date}) {
      this.focus = date
      this.type = 'day'
    },
    close: function () {
      this.selectedOpen = false
    },
    saveAgendaView(view) {
      this.$emit("update", {
        widgetName: "agenda",
        dimension: "preferences.view",
        data: view
      })
    }
  }
}
</script>

<style>

  .grey.darken-5 {
    background-color: #1E1E1E !important;
  }

</style>